<template>
  <div>
    <section class="section">
      <div class="row">
        <div class="col">
          <Panel type="sheet">
            <template v-slot:headline>初期設定</template>
            <template v-slot:headerContent>
              <router-link class="btn btn-sm btn-bd-main" :to="{ name: 'ShopSettingDefault' }">編集する</router-link>
            </template>
            <template v-slot:body>
              <table class="table">
                <tbody>
                  <tr>
                    <th>受付メールアドレス</th>
                    <td>
                      <template v-for="(reception, index) in tableData.receptions">
                        {{ reception }}<br :key="index" />
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>営業の状態</th>
                    <td>{{ tableData.openFlag ? '営業中' : '休業中' }}</td>
                  </tr>
                  <tr>
                    <th>ヘッダー用アイコン</th>
                    <td>
                      <div v-if="tableData.logo" class="thumbnail">
                        <img class="thumbnail-img" :src="tableData.logo" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Panel>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>店舗基本設定</template>
          <template v-slot:headerContent>
            <router-link
              v-if="isShopSettingBasic"
              class="btn btn-sm btn-bd-main"
              :to="{ name: 'ShopSettingBase' }"
            >
              編集する
            </router-link>
          </template>
          <template v-slot:body>
            <table class="table">
              <tbody>
                <tr>
                  <th>店舗画像</th>
                  <td>
                    <ul class="listGrid" v-if="tableData.images.length">
                      <li class="listGrid-item" v-for="(image, index) in tableData.images" :key="index">
                        <div class="thumbnail">
                          <img class="thumbnail-img" :src="image.url" />
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>{{ tableData.zipcode }}</td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td>{{ tableData.fullAddress }}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{{ tableData.telephone }}</td>
                </tr>
                <tr>
                  <th>営業時間</th>
                  <td>{{ tableData.onTime }}</td>
                </tr>
                <tr>
                  <th>定休日</th>
                  <td>{{ tableData.holiday }}</td>
                </tr>
                <tr>
                  <th>サブタイトル</th>
                  <td>{{ tableData.subTitle }}</td>
                </tr>
                <tr>
                  <th>紹介文</th>
                  <td>{{ tableData.description }}</td>
                </tr>
                <tr>
                  <th>アクセス</th>
                  <td>{{ tableData.access }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </Panel>
      </div>
    </section>
    <section class="section">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>店舗詳細設定</template>
          <template v-slot:headerContent>
            <router-link
              v-if="isShopSettingAdvanced"
              class="btn btn-sm btn-bd-main"
              :to="{ name: 'ShopSettingDetail' }"
            >
              編集する
            </router-link>
          </template>
          <template v-slot:body>
            <table class="table">
              <tbody>
                <tr v-for="(detail, index) in tableData.details" :key="index">
                  <th>{{ detail.title }}</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ detail.content }}</p>
                  </td>
                </tr>
                <tr>
                  <th>ホームページ</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.site }}</p>
                  </td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.email }}</p>
                  </td>
                </tr>
                <tr>
                  <th>Facebook</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.facebook }}</p>
                  </td>
                </tr>
                <tr>
                  <th>X（Twitter）</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.twitter }}</p>
                  </td>
                </tr>
                <tr>
                  <th>Instagram</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.instagram }}</p>
                  </td>
                </tr>
                <tr>
                  <th>LINE</th>
                  <td>
                    <p v-if="isShopSettingAdvanced">{{ tableData.sns.line }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </Panel>
      </div>
    </section>
  </div>
</template>

<script>
//mixins
import shop from '@/mixins/module/shop';
import { mapGetters } from 'vuex';

export default {
  name: 'ShopSettingInfo',
  data: function() {
    return {};
  },
  mixins: [shop],
  created() {
    this.$parent.editPage = 'info';
  },
  computed: {
    tableData() {
      return {
        ...this.shopConfig,
        logo: this.shopConfig.logo?.url || '',
        images: this.shopConfig?.images?.filter(e => e.id) || [],
        fullAddress: `${this.shopConfig.prefectureName || ''}${this.shopConfig.municipalityName || ''}${this.shopConfig.streetAddress || ''}${this.shopConfig.buildingName || ''}`,
        sns: this.shopConfig.sns || {}
      };
    },
    ...mapGetters({
      parentEventDetail: 'event/parentEventDetail',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
    }),
    isShopSettingBasic() {
      return this.isGmoOrOfficeLoginStore || this.parentEventDetail?.shopSettings?.basic;
    },
    isShopSettingAdvanced() {
      return this.isGmoOrOfficeLoginStore || this.parentEventDetail?.shopSettings?.advanced;
    }
  },
};
</script>
